import ApplicationController from './application_controller'
import Rails from '@rails/ujs';

export default class extends ApplicationController {
  static targets = [ 'statusForm']
  
  connect(){
    
    // consumer.subscriptions.consumer.disconnect();
    // consumer.subscriptions.consumer.connect();

    super.connect();
    
  }

  changeStatus(){
    // console.log(I18n.t("community.settings.rewards"))
    // this.stimulate('Redeem#change_status', this.statusFormTarget)
    //   .then(() => {
    //     iziToast.success({
    //       title: I18n.t("community.settings.redeems.items.change_status.success.title"),
    //       message: I18n.t("community.settings.redeems.items.change_status.success.message"),
    //       class: "notification is-light"
    //     });
        
    //   })
    //   .catch(() => console.log("ERROR"))

    this.element.requestSubmit();

    const status_container = this.statusFormTarget.querySelector(".select")
    const status_value = this.statusFormTarget.querySelector("select").value
    
    if(status_value == 'opened'){
      status_container.classList.add('is-danger')
      status_container.classList.remove('is-success')
    } else {
      status_container.classList.remove('is-danger')
      status_container.classList.add('is-success')
    }
      

  }

  destroy(event) {
    event.preventDefault();
    event.stopPropagation();

    var controller = this;
    var stimulusTarget = event.currentTarget;

    Bulma.create('alert', {
      type: 'danger',
      title: I18n.t("community.settings.redeems.destroy.confirm.title"),
      body: I18n.t("community.settings.redeems.destroy.confirm.body"),
      confirm: {
        label: I18n.t("community.settings.redeems.destroy.confirm.btn.confirm"),
        onClick: function(){
          controller.stimulate("Admin::UserItems#destroy", stimulusTarget);
        }
      },
      cancel: I18n.t("community.settings.redeems.destroy.confirm.btn.cancel")
    });

    
    // this.hideLoading();
  }

  create(event){
    event.preventDefault();

    this.stimulate('Redeem#create', event.target)
  }

  beforeCreate(element, reflex, noop, reflexId){
    Rails.disableElement(element.querySelector("[type=submit]"))
    // element.setAttribute("disabled", "disabled")
  }

  afterCreate(element, reflex, noop, reflexId){
    Rails.enableElement(element.querySelector("[type=submit]"))
    // element.removeAttribute("disabled")
  }


}